<template>
  <v-slide-group
    v-if="suggestedBooks && suggestedBooks.length > 0"
    :show-arrows="true"
    class="slide-suggested py-5 prevent-select"
  >
    <alerta-literatura
      :literaturaAlert.sync="literaturaAlert"
      :literaturaAlertMsg="literaturaAlertMsg"
      :books="allBooks"
      @addToCart="addToCart"
    ></alerta-literatura>

    <v-slide-item
      v-for="item in suggestedBooks"
      :key="'item-'+item.book_id"
      v-slot="{ active, toggle }"
    >
      <v-card
        class="d-flex flex-column mr-1 ml-2" flat>

        <div
          @click="$router.push({path: '/dossiers-selectivitat/book/' + item.book_id})"
          class="d-flex flex-column">
          <img
            class="rounded-lg"
            width="125"
            height="120"
            style="width: 100%; cursor: pointer; object-fit: contain"
            :style="bookPage ? 'max-width: 247px' : 'max-width: 147px'"
            v-lazyload="`https://examenselectivitat.cat:3000/api/images/other/${item.subject_name}_examens_2025_sm.webp`"
          >


          <span
            style="cursor: pointer; flex: 1; max-width: 150px"
            class="font-rubik font-weight-medium mt-2 two-lines"
            :style="bookPage ? '' : 'font-size: 14px;'"

          >
          {{ item.new_subject_name || item.subject_name }}
        </span>


          <!-------- DISCOUNT PRICE ---------->
          <span v-if="item.offerPrices && item.offerPrices.offer" class="align-center d-flex justify-end">
          <v-chip color="red darken-2" dark class="font-weight-bold mr-2 px-1" x-small label>-20%</v-chip>
          <span v-if="item.offerPrices.normal" class="mr-1">{{ item.offerPrices.offer }} x</span>10.95 €
        </span>


          <!-------- NORMAL PRICE ---------->
<!--          <span v-else class="font-rubik"-->
<!--                :style="bookPage ? 'font-size: 14px;' : 'font-size: 12px'"-->

<!--          >-->
<!--          10.95 €-->
<!--        </span>-->
            <div
                v-if="item"
                class="d-flex align-center">
                <span class="font-weight-regular font-rubik"
                      :style="item.oldPrice ? 'color: red; font-size: 16px !important' : ''"
                      style="font-size: 15px !important;">{{ item.price }}€</span>
                <span
                    v-if="item.oldPrice"
                    class="font-weight-regular font-rubik ml-3"
                    style="font-size: 15px !important; text-decoration: line-through"
                >
                {{ item.oldPrice }}€
              </span>
            </div>
        </div>

        <v-hover v-slot:default="{ hover }">
          <v-btn
            @click="addToCart(item)"
            height="34"
            style=" width: 100%; letter-spacing: normal !important"
            depressed
            class="text-none font-weight-medium mb-3 mt-3 rounded font-rubik"
            :style="(bookPage ? '' : 'max-height: 28px !important; font-size: 12px;' ) + (hover ? 'color: #256EFF !important; outline: 2px solid #256EFF !important; background: transparent !important' : 'background: #256EFF !important; color: white')"

          >
            Afegir a la cistella
          </v-btn>
        </v-hover>

      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import AlertaLiteratura from "../../components/AlertaLiteratura.vue";

export default {
  name: "CheckoutSuggested",
  components: {AlertaLiteratura},
  props: {
    allBooks: Array,
    items: Array,
    bookPage: {
      default: false
    },
    fromExams: {
      default: false
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_AXIOS_URI,
      literaturaAlert: false,
      literaturaAlertMsg: ""
    }
  },
  computed: {
    suggestedBooks() {
      if (this.allBooks && this.allBooks.length > 0) {
        const data = this.allBooks

        let books = []
        data.forEach(d => books = books.concat(d.subjects));

        // Sort by the branch id of the actual book and then the popularity of sold units
        let result = []

        data.forEach(d => result = result.concat(...d.subjects))
        result.sort((a, b) => (a.sold_units > b.sold_units ? -1 : 1))
        const interested_branches = this.items.map(i => i.branch_id);
        result.sort((a, b) => (interested_branches.includes(a.branch_id) ? -1 : 1))

        const books_ids = this.items.map(i => parseInt(i.book_id));
        console.log("books_ids", books_ids)
        return result.filter(b => !books_ids.includes(parseInt(b.book_id)) && this.$route.params.book_id != b.book_id);
      }
      return []
    },
  },
  methods: {

    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
    async addToCart(book) {

      if (this.fromExams) {
        await this.$router.push({path: '/dossiers-selectivitat/book/' + book.book_id})
      }

      const book_id = book.book_id;
      this.$root.cart++;
      let list = this.$cookies.get('cart');
      if (!list[book_id]) list[book_id] = 0;
      list[book_id]++

      // Alert LITERATURA
      if (book_id === 29) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CASTELLANA"
      } else if (book_id === 30) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CATALANA"
      }

      this.$cookies.set('cart', list)
      this.$emit('addItem');


    },
  }

}
</script>

<style scoped>

</style>
